import actions from './actions';

const initState = {
  tag: undefined,
  selectedMail: -1,
  filterAttr: { bucket: "Today's inline patient" },
  selectedAddPatient: null,
  composeMail: false,
  searchString: '',
  showModal: false,
};

export default function mailReducer(state = initState, action) {
  switch (action.type) {
    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        composeMail: false,
        selectedMail: -1,
        filterAttr: { ...action.filterAttr },
      };
    case actions.SELECTED_MAIL:
      return {
        ...state,
        selectedMail: action.selectedMail,
        allMails: action.allMails,
      };
    case actions.SELECTED_ADD_PATIENT_TYPE:
      return {
        ...state,
        selectedAddPatient: action.selectAddPatientType,
      };
    case actions.COMPOSE_MAIL:
      return {
        ...state,
        replyMail: false,
        composeMail: action.composeMail,
      };
    case actions.SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.showModal,
      };
    default:
      return state;
  }
}
