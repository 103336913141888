import actions from './actions';
import clone from 'clone';
import { newInvoice } from './config';

const initState = {
  tag: undefined,
  selectedMail: -1,
  filterAttr: { bucket: "Patient database" },
  selectedAddPatient: null,
  composeMail: false,
  searchString: '',
  showModal: false,
  invoices: [],
  initialInvoices: false,
  currentInvoice: {},
  editableInvoice: {},
  isNewInvoice: false,
  enableEditView: false,
};

export default function mailReducer(state = initState, action) {
  switch (action.type) {
    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        composeMail: false,
        selectedMail: -1,
        filterAttr: { ...action.filterAttr },
      };
    case actions.SELECTED_MAIL:
      return {
        ...state,
        selectedMail: action.selectedMail,
        allMails: action.allMails,
      };
    case actions.SELECTED_ADD_PATIENT_TYPE:
      return {
        ...state,
        selectedAddPatient: action.selectAddPatientType,
      };
    case actions.COMPOSE_MAIL:
      return {
        ...state,
        replyMail: false,
        composeMail: action.composeMail,
      };
    case actions.SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.showModal,
      };
    case actions.UPDATE_INVOICE: {
      const currentInvoice = action.invoice
        ? action.invoice
        : state.currentInvoice;
      return {
        ...state,
        invoices: action.invoices,
        currentInvoice: clone(currentInvoice),
        initialInvoices: true,
        isNewInvoice: false,
        enableEditView: false,
      };
    }
    case actions.SELECT_CURRENT_INVOICE: {
      const invoices = state.invoices;
      const index = invoices.map(invoice => invoice.id).indexOf(action.id);
      const isNewInvoice = index === -1;
      const currentInvoice = isNewInvoice
        ? {
          id: action.id,
          number: `#${action.id}`,
          key: action.id,
          ...newInvoice,
        }
        : invoices[index];
      const enableEditView = isNewInvoice;
      return {
        ...state,
        currentInvoice,
        isNewInvoice,
        enableEditView,
        editableInvoice: clone(currentInvoice),
      };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableInvoice: clone(state.currentInvoice),
      };
    case actions.UPDATE_EDIT_INVOICE:
      return {
        ...state,
        editableInvoice: clone(action.invoice),
      };
    default:
      return state;
  }
}
