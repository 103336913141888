const mailActions = {

  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  SELECTED_MAIL: 'SELECTED_MAIL',
  SELECTED_ADD_PATIENT_TYPE: 'SELECTED_ADD_PATIENT_TYPE',
  COMPOSE_MAIL: 'COMPOSE_MAIL',
  SEARCH_STRING: 'SEARCH_STRING',
  TOGGLE_MODAL: 'TOGGLE_MODAL',

  filterAction: newFilterAttr => {
    return (dispatch, getState) => {
      const filterAttr = getState().Reception.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: mailActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },

  selectMail: selectedMail => ({
    type: mailActions.SELECTED_MAIL,
    selectedMail,
  }),

  selectAddPatientType: selectedAddPatientType => ({
    type: mailActions.SELECTED_MAIL,
    selectedAddPatientType,
  }),

  changeComposeMail: composeMail => ({
    type: mailActions.COMPOSE_MAIL,
    composeMail,
  }),
  
  changeSearchString: searchString => ({
    type: mailActions.SEARCH_STRING,
    searchString,
  }),

  toggleAddPatientModal: showModal => {
    return (dispatch, getState) => {

      const showModal = !getState().Reception.showModal;

      dispatch({
        type: mailActions.TOGGLE_MODAL,
        showModal,
      })
    }
  }
};
export default mailActions;
