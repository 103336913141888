import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import contactSagas from '@iso/redux/contacts/saga';
import invoicesSagas from '@iso/redux/invoice/saga';
import receptionSagas from '@iso/redux/reception/saga';
import cashierSagas from '@iso/redux/cashier/saga';
import andrologySagas from '@iso/redux/andrology/saga';
import counsellorSagas from '@iso/redux/counsellor/saga';
import rmoSagas from '@iso/redux/rmo/saga';
import consultantSagas from '@iso/redux/consultant/saga';
import pathologySagas from '@iso/redux/pathology/saga';
import ipdSagas from '@iso/redux/ipd/saga';
import mycontactsSagas from '@iso/redux/mycontacts/saga';
import mailSagas from '@iso/redux/mail/saga';
import notesSagas from '@iso/redux/notes/saga';
import todosSagas from '@iso/redux/todos/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import cardsSagas from '@iso/redux/card/saga';
import chatSagas from '@iso/redux/chat/sagas';
import youtubeSearchSagas from '@iso/redux/youtubeSearch/sagas';
import githubSagas from '@iso/redux/githubSearch/sagas';
import articles from '@iso/redux/articles/sagas';
import investors from '@iso/redux/investors/sagas';
import scrumBoardSaga from '@iso/redux/scrumBoard/saga';
import profileSaga from '@iso/redux/profile/saga';
import quizSaga from '@iso/redux/quiz/saga';

export default function* rootSaga(getState) {
  yield all([
    consultantSagas(),
    pathologySagas(),
    andrologySagas(),
    counsellorSagas(),
    ipdSagas(),
    mycontactsSagas(),
    authSagas(),
    contactSagas(),
    rmoSagas(),
    receptionSagas(),
    cashierSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    chatSagas(),
    youtubeSearchSagas(),
    githubSagas(),
    articles(),
    investors(),
    scrumBoardSaga(),
    profileSaga(),
    quizSaga(),
  ]);
}
