const mailActions = {

  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  SELECTED_MAIL: 'SELECTED_MAIL',
  SELECTED_ADD_PATIENT_TYPE: 'SELECTED_ADD_PATIENT_TYPE',
  COMPOSE_MAIL: 'COMPOSE_MAIL',
  SEARCH_STRING: 'SEARCH_STRING',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  GET_INVOICE: 'GET_INVOICE',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  UPDATE_INVOICE_SAGA: 'UPDATE_INVOICE_SAGA',
  SELECT_CURRENT_INVOICE: 'SELECT_CURRENT_INVOICE',
  TOGGLE_VIEW: 'INVOICE_TOGGLE_VIEW',
  UPDATE_EDIT_INVOICE: 'INVOICE_UPDATE_EDIT_INVOICE',

  initData: () => ({ type: mailActions.GET_INVOICE }),

  filterAction: newFilterAttr => {
    return (dispatch, getState) => {
      const filterAttr = getState().Cashier.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: mailActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },

  selectMail: selectedMail => ({
    type: mailActions.SELECTED_MAIL,
    selectedMail,
  }),

  selectAddPatientType: selectedAddPatientType => ({
    type: mailActions.SELECTED_MAIL,
    selectedAddPatientType,
  }),

  changeComposeMail: composeMail => ({
    type: mailActions.COMPOSE_MAIL,
    composeMail,
  }),
  
  changeSearchString: searchString => ({
    type: mailActions.SEARCH_STRING,
    searchString,
  }),

  toggleAddPatientModal: showModal => {
    return (dispatch, getState) => {

      const showModal = !getState().Reception.showModal;

      dispatch({
        type: mailActions.TOGGLE_MODAL,
        showModal,
      })
    }
  },

  deleteInvoice: selected => {
    return (dispatch, getState) => {
      const invoices = getState().Invoices.invoices;
      const newInvoices = [];
      invoices.forEach(invoice => {
        const selectedIndex = selected.indexOf(invoice.key);
        if (selectedIndex === -1) {
          newInvoices.push(invoice);
        }
      });
      dispatch({
        type: mailActions.UPDATE_INVOICE_SAGA,
        invoices: newInvoices,
      });
    };
  },

  updateInvoice: invoice => {
    return (dispatch, getState) => {
      const invoices = getState().Invoices.invoices;
      const index = invoices.map(inv => inv.id).indexOf(invoice.id);
      if (index === -1) {
        invoices.push(invoice);
      } else {
        invoices[index] = invoice;
      }
      dispatch({
        type: mailActions.UPDATE_INVOICE_SAGA,
        invoices,
        invoice,
      });
    };
  },

  selectCurrentInvoice: id => ({ type: mailActions.SELECT_CURRENT_INVOICE, id }),

  toggleView: view => ({ type: mailActions.TOGGLE_VIEW, view }),

  editInvoice: invoice => ({ type: mailActions.UPDATE_EDIT_INVOICE, invoice }),
  
};
export default mailActions;
